$(function () {
    "use strict";

    $('.knob2').knob({
        'format': function (value) {
            return value + '%';
        }
    });

    // progress bars
    $('.progress .progress-bar').progressbar({
        display_text: 'none'
    });
    $('.sparkline-pie').sparkline('html', {
        type: 'pie',
        offset: 90,
        width: '147px',
        height: '147px',
        sliceColors: ['#02b5b2', '#445771', '#ffcd55']
    })

    var sparkline1 = [4, 1, 5, 7, 9, 9, 8, 7, 6, 6, 4, 7, 8, 4, 3, 2, 2, 5, 6, 7];
    var sparkline2 = [6, 4, 7, 8, 4, 3, 2, 2, 5, 6, 7, 4, 1, 5, 7, 9, 9, 8, 7, 6];

    const BodySparkline1 = {
        composite: true,
        fillColor: false,
        lineColor: '#02b5b2',
        lineWidth: '1',
        chartRangeMin: 0,
        chartRangeMax: 10,
    };

    const BodySparkline2 = {
        composite: true,
        fillColor: false,
        lineColor: '#ffcd55',
        lineWidth: '1',
        chartRangeMin: 0,
        chartRangeMax: 10
    };

    const BodyHtml = {
        fillColor: false,
        lineColor: '#445771',
        width: '200px',
        height: '30px',
        lineWidth: '1',
    }

    function setSparkline_html() {
        BodyHtml();
    }

    function setSparkline_2() {
        sparkline1();
        BodySparkline1();
    }
    function setSparkline_3() {
        sparkline2();
        BodySparkline2();
    }

    $('#sparkline-compositeline').sparkline(
        setSparkline_html,
        setSparkline_2,
        setSparkline_3
    );

    $('.sparkbar').sparkline('html', { type: 'bar' });

    // notification popup
    toastr.options.closeButton = true;
    toastr.options.positionClass = 'toast-bottom-right';
});
var dataStackedBar = {
    labels: ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6'],
    series: [
        [2350, 3205, 4520, 2351, 5632, 3205],
        [2541, 2583, 1592, 2674, 2323, 2583],
        [1212, 5214, 2325, 4235, 2519, 5214],
    ]
};

// multiple chart
var dataMultiple = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    series: [{
        name: 'Sales',
        data: [825, 380, 350, 621, 410, 450, 570, 400, 555, 225, 750, 900],
    }, {
        name: 'Marketing',
        data: [215, 350, 25, 380, 400, 249, 480, 258, 555, 465, 700, 496],
    }, {
        name: 'Design',
        data: [59, 350, 360, 326, 208, 450, 258, 359, 189, 528, 700, 258],
    }, {
        name: 'Support',
        data: [349, 350, 360, 250, 409, 237, 357, 523, 182, 520, 125, 800],
    }, {
        name: 'Develpment',
        data: [149, 350, 256, 380, 400, 358, 159, 523, 147, 349, 700, 89],
    }]
};
var options = {
    lineSmooth: true,
    height: "305px",
    low: 0,
    high: 'auto',
    series: {
        'Design': {
            showPoint: true,
        },
    },
    axisY: {
        labelInterpolationFnc: function (value) {
            return (value / 100).toString() + 'k';
        }
    },
    options: {
        responsive: true,
        legend: true
    },
    plugins: [
    ]
};

